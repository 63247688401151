import React from "react";
import Link from "../Link";
import Label from "../Label";
import {
  InstagramIcon,
  LinkedInIcon,
  Logo,
  TelegramIcon,
  TwitterIcon,
} from "../../assets/svgs";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <div className="footer">
      <div className="footer__widget">
        <div className="footer__widget__left">
          <div className="footer__widget__left__logo">
            <Link
              styles="navigation__logo"
              url={`${process.env.REACT_APP_BRAINTRUST_URL}?hsLang=en`}
            >
              <Logo />
            </Link>
          </div>
          <div className="footer__widget__left__text">
            <Label type="primary" size="large">
              <p>Own it.</p>
              <p>Your work. Your network. Your future.</p>
            </Label>
          </div>
          <div className="footer__widget__left__social">
            <Link
              styles="footer__widget__left__social__item"
              url="https://www.linkedin.com/company/usebraintrust/"
            >
              <LinkedInIcon width={21.88} height={25} />
            </Link>
            <Link
              styles="footer__widget__left__social__item"
              url="https://twitter.com/usebraintrust"
            >
              <TwitterIcon width={21.88} height={25} />
            </Link>
            <Link
              styles="footer__widget__left__social__item"
              url="https://www.instagram.com/usebraintrust/"
            >
              <InstagramIcon width={21.88} height={25} />
            </Link>
            <Link
              styles="footer__widget__left__social__item"
              url="https://t.me/braintrustofficial"
            >
              <TelegramIcon width={21.88} height={25} />
            </Link>
          </div>
        </div>
        <div className="footer__widget__right">
          <div className="footer__widget__right__menu">
            <div className="footer__widget__right__menu__list">
              <div className="footer__widget__right__menu__list__title">
                <Label type="primary" size="medium" font="medium">
                  Platform
                </Label>
              </div>
              <ul className="footer__widget__right__menu__list__content">
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}how-it-works-for-talent`}
                  >
                    <Label type="primary" size="medium">
                      Talent
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}braintrust-for-enterprise`}
                  >
                    <Label type="primary" size="medium">
                      Enterprises
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link url={`${process.env.REACT_APP_BRAINTRUST_URL}payments`}>
                    <Label type="primary" size="medium">
                      Payments + Fees
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}dispute-resolution`}
                  >
                    <Label type="primary" size="medium">
                      Dispute Resolution
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}frequently-asked-questions`}
                  >
                    <Label type="primary" size="medium">
                      FAQ
                    </Label>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__widget__right__menu__list">
              <div className="footer__widget__right__menu__list__title">
                <Label type="primary" size="medium" font="medium">
                  Resources
                </Label>
              </div>
              <ul className="footer__widget__right__menu__list__content">
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}braintrust-for-enterprise`}
                  >
                    <Label type="primary" size="medium">
                      For Enterprises
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}how-it-works-for-talent`}
                  >
                    <Label type="primary" size="medium">
                      For Freelancers
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}resources#eBook1`}
                  >
                    <Label type="primary" size="medium">
                      eBooks
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link url={`${process.env.REACT_APP_BRAINTRUST_URL}blog`}>
                    <Label type="primary" size="medium">
                      Blog
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link url={`${process.env.REACT_APP_BRAINTRUST_URL}podcast`}>
                    <Label type="primary" size="medium">
                      Podcast
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}resources#webinar1`}
                  >
                    <Label type="primary" size="medium">
                      Webinars
                    </Label>
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer__widget__right__menu__list">
              <div className="footer__widget__right__menu__list__title">
                <Label type="primary" size="medium" font="medium">
                  About
                </Label>
              </div>
              <ul className="footer__widget__right__menu__list__content">
                <li className="footer__widget__right__menu__list__content__item">
                  <Link url={`${process.env.REACT_APP_BRAINTRUST_URL}about`}>
                    <Label type="primary" size="medium">
                      Company
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link url={`${process.env.REACT_APP_BRAINTRUST_URL}press`}>
                    <Label type="primary" size="medium">
                      Press
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}community`}
                  >
                    <Label type="primary" size="medium">
                      Community
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}btrst-token`}
                  >
                    <Label type="primary" size="medium">
                      BTRST
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}whitepaper`}
                  >
                    <Label type="primary" size="medium">
                      Whitepaper
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}tokenomics`}
                  >
                    <Label type="primary" size="medium">
                      Token Economics
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link
                    url={`${process.env.REACT_APP_BRAINTRUST_URL}governance`}
                  >
                    <Label type="primary" size="medium">
                      Governance
                    </Label>
                  </Link>
                </li>
                <li className="footer__widget__right__menu__list__content__item">
                  <Link url="https://support.usebraintrust.com/hc/en-us">
                    <Label type="primary" size="medium">
                      Contact Us
                    </Label>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer__disclaimer">
        The Braintrust Token is an ERC-20 token issued on the Ethereum
        blockchain network by the Braintrust Technology Foundation, a nonprofit
        foundation. Braintrust Tokens do not represent any right to or claim on
        the Braintrust network or any other person or entity, and has been
        adopted by the Braintrust network and users for various activities on
        the network only, such as for staking, governance, voting and payment
        purposes.
      </div>
      <div className="footer__menu">
        <div className="footer__menu__copyright">
          <Label type="second" size="medium">
            ©Braintrust {currentYear}
          </Label>
        </div>
        <div className="footer__menu__list">
          <Link
            styles="footer__menu__list__item"
            url={`${process.env.REACT_APP_BRAINTRUST_URL}terms`}
          >
            <Label type="second" size="small">
              Terms
            </Label>
          </Link>
          <Link
            styles="footer__menu__list__item"
            url={`${process.env.REACT_APP_BRAINTRUST_URL}code-of-conduct`}
          >
            <Label type="second" size="small">
              Code of Conduct
            </Label>
          </Link>
          <Link
            styles="footer__menu__list__item"
            url={`${process.env.REACT_APP_BRAINTRUST_URL}network-standards`}
          >
            <Label type="second" size="small">
              Network Standards
            </Label>
          </Link>
          <Link
            styles="footer__menu__list__item"
            url={`${process.env.REACT_APP_BRAINTRUST_URL}btrst-purchaser-terms`}
          >
            <Label type="second" size="small">
              Purchaser Terms
            </Label>
          </Link>
          <Link
            styles="footer__menu__list__item"
            url={`${process.env.REACT_APP_BRAINTRUST_URL}referral-terms`}
          >
            <Label type="second" size="small">
              Referral Terms
            </Label>
          </Link>
          <Link
            styles="footer__menu__list__item"
            url={`${process.env.REACT_APP_BRAINTRUST_URL}privacy-policy`}
          >
            <Label type="second" size="small">
              Privacy
            </Label>
          </Link>
          <Link
            styles="footer__menu__list__item"
            url={`${process.env.REACT_APP_BRAINTRUST_URL}cookie-policy`}
          >
            <Label type="second" size="small">
              Cookie Policy
            </Label>
          </Link>
        </div>
      </div>
    </div>
  );
}

import { DEFAULTS_EARNERS_FILTERS_VALUE } from "../hooks/earners-list";

export const getDashboardData = () => {
  return fetch(`${process.env.REACT_APP_API}/dashboard`)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
};

export const getBTRSTEarnersList = (params = {}) => {
  const url = new URL(`${process.env.REACT_APP_API}/dashboard/token_earners`);
  const paramsToUrl = { ...params };

  if (paramsToUrl.year === DEFAULTS_EARNERS_FILTERS_VALUE.year) {
    delete paramsToUrl.year;
  }

  delete paramsToUrl.sort;
  url.search = new URLSearchParams(paramsToUrl).toString();

  return fetch(url)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
};

export const getSkillRates = (params = { order: "-rate" }) => {
  const url = new URL(`${process.env.REACT_APP_API}/dashboard/skill_rates`);

  url.search = new URLSearchParams(params).toString();

  return fetch(url)
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
};

import React from "react";

import Label from "../Label";
import Link from "../Link";
import Button from "../Button";
import { HIRE_TALENT_LINK, NAV_LINKS, SIGN_UP_LINK } from "../../constants";
import { Logo, ArrowDownIcon } from "../../assets/svgs";

export default function Navigation({ scrolling }) {
  const goToSignUp = () => {
    window.open(SIGN_UP_LINK, "_blank");
  };

  const goToHireTalent = () => {
    window.open(HIRE_TALENT_LINK, "_blank");
  };

  return (
    <div className={`nav ${scrolling ? "nav__scrolled" : ""}`}>
      <div className="navigation">
        <Link
          styles="navigation__logo"
          url={`${process.env.REACT_APP_BRAINTRUST_URL}?hsLang=en`}
        >
          <Logo className="navigation__logo__img" />
        </Link>
        <div className="navigation__items">
          {NAV_LINKS.map((link) => {
            const className = [
              `navigation__item`,
              link.subLinks ? "navigation__item--has-sub" : undefined,
            ]
              .filter(Boolean)
              .join(" ");

            return (
              <div className={className} key={link.label}>
                <div className="navigation__item__display">
                  <Link
                    url={link.url}
                    size="small"
                    className="navigation__item__display__link"
                  >
                    <Label type="primary" size="small" font="medium">
                      {link.label}
                    </Label>
                  </Link>
                  {!!link.subLinks && (
                    <ArrowDownIcon className="navigation__item__icon" />
                  )}
                </div>
                {!!link.subLinks && <SubMenu data={link.subLinks} />}
              </div>
            );
          })}
          <div className="navigation_buttons md">
            <div className="navigation__item nav-btn-container">
              <Button
                border="round"
                type="primary"
                size="small"
                styles="btn-ht nav-btn"
                onClick={goToSignUp}
              >
                Sign Up
              </Button>
            </div>
            <div className="navigation__item nav-btn-container">
              <Button
                border="round"
                type="white"
                size="small"
                styles="btn-ht nav-btn"
                onClick={goToHireTalent}
              >
                Hire Talent
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const SubMenu = ({ data }) => {
  return (
    <div className="navigation__item__submenu">
      {data.map((item) => (
        <Link
          key={item.label}
          url={item.url}
          size="small"
          styles="navigation__item__submenu__item"
        >
          {item.label}
        </Link>
      ))}
    </div>
  );
};

import React, { useState } from "react";

import Button from "../Button";
import Label from "../Label";
import Title from "../Title";
import ButtonGroup from "../ButtonGroup";
import { CloseWhiteIcon, DetailIcon } from "../../assets/svgs";
import ButtonDropdown from "../ButtonDropdown";
import { getGSVYears, getGSVQuaters, getGSVValue } from "./helpers";
import { numberWithCommas } from "../../util";

export default function GSV({ data, quarterlyData }) {
  const [showIntro, setShowIntro] = useState(false);

  const [selectedYear, setSelectedYear] = useState("total");
  const [selectedQuarter, setSelectedQuarter] = useState("total");
  const [openDropdown, setOpenDropdown] = useState(false);

  const years = getGSVYears(quarterlyData);
  const quarters = getGSVQuaters(quarterlyData, selectedYear);

  const currentData = getGSVValue(
    data,
    quarterlyData,
    selectedYear,
    selectedQuarter
  );

  const onToggleDropdown = () => {
    setOpenDropdown(!openDropdown);
  };

  const onChangeYear = (property, value) => {
    setSelectedQuarter("total");
    setSelectedYear(value);
  };

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  return (
    <div className="dashboard__gsv" data-aos="fade-up" data-aos-delay="50">
      <div className="dashboard__gsv__overlay" />
      <div className={`dashboard__gsv__main ${`dashboard__gsv__main__${showIntro ? 'hide' : 'show'}`}`}>
        <div className="dashboard__gsv__header">
          <Title type="white" size="medium" font="medium">
            Dollars earned by the Braintrust community.
          </Title>
          <Button onClick={onToggle}>
            <DetailIcon className="icon__detail" />
          </Button>
        </div>
        <div className="gsv-container dashboard__gsv__content">
          {(typeof currentData === 'string' || typeof currentData === 'number') && (
            <>
              <div className="dashboard__gsv__content__buttons">
                <ButtonDropdown
                  open={openDropdown}
                  selected={selectedYear}
                  property="year"
                  menu={years}
                  onToggle={onToggleDropdown}
                  onItemClick={onChangeYear}
                />
                {quarters.length !== 0 && (
                  <ButtonGroup value={selectedQuarter} options={quarters} onClick={setSelectedQuarter} />
                )}
              </div>
              <p className="gsv-container__dollar-amount">${numberWithCommas(Math.floor(currentData))}</p>
            </>
          )}
          <Label type="white" size="large">
            Represented by Braintrust's GSV{' '}
            <span className="dashboard__gsv__content__mobile-br">(Gross Service Value)</span>
          </Label>
        </div>
      </div>
      <div className={`dashboard__gsv__intro ${`dashboard__gsv__intro__${showIntro ? 'show' : 'hide'}`}`}>
        <div className="dashboard__gsv__header">
          <Title type="white" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseWhiteIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__gsv__intro__content">
          <div className="dashboard__gsv__intro__content__item">
            <div className="dashboard__gsv__intro__content__item__left">
              <Label type="white" size="large" font="medium">
                Gross Service Value
              </Label>
            </div>
            <div className="dashboard__gsv__intro__content__item__right">
              <Label type="white" size="medium">
                GSV represents the actual dollar amount that has come into Braintrust before being distributed to the
                freelance talent. This is how Braintrust measures the network size and growth. For example, if a client
                hires three developers who are billing at 10K/month. The GSV for the month would be $34.5k ($10k x 3 dev
                + 15% BT fee).
              </Label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useRef, useState } from "react";

import Button from "../Button";
import Label from "../Label";
import Title from "../Title";
import { Avatar } from "./Avatar";
import {
  ChevronLeftIcon,
  ChevronLeftActiveIcon,
  ChevronRightIcon,
  ChevronRightActiveIcon,
  LogoIcon,
  CloseBlackIcon,
  BlackDetailIcon,
} from "../../assets/svgs";

import { TabCarousel } from "../TabCarousel";
import Dropdown from "../Dropdown";
import { sorts } from "../../constants";
import {
  BTRST_EARNERS_FILTERS,
  DEFAULTS_EARNERS_FILTERS_VALUE,
  TIME_FILTER_OPTIONS,
  useEarnersList,
} from "../../hooks/earners-list";

export default function BTRSTLeaderBoard({ tokenEarnerYears }) {
  const {
    filters,
    updateFilter,
    totalNumberOfReferrers,
    currentPage,
    totalPage,
    handlePrevPage,
    handleNextPage,
    earnerList,
  } = useEarnersList();
  const tokenEarnerYearsAsOptions =
    tokenEarnerYears?.length > 0
      ? tokenEarnerYears
          ?.map((option) => ({
            label: option,
            value: option,
          }))
          .sort((a, b) => b.value - a.value)
      : [];
  const tokenYearsOption = [
    ...TIME_FILTER_OPTIONS,
    ...tokenEarnerYearsAsOptions,
  ];
  const [isTimeFilterOpen, setTimeFilterOpen] = useState(false);
  const [isSortFilterOpen, setSortFilterOpen] = useState(false);
  const [showIntro, setShowIntro] = useState(false);
  const timeFilterRef = useRef();
  const sortRef = useRef();

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  const handleClickOutside = (event) => {
    if (
      isTimeFilterOpen &&
      timeFilterRef &&
      !timeFilterRef.current.contains(event.target)
    )
      setTimeFilterOpen(false);
    if (isSortFilterOpen && sortRef && !sortRef.current.contains(event.target))
      setSortFilterOpen(false);
  };

  const Pagination = () => (
    <div className="dashboard__leaderboard__pagination">
      <Button
        styles="dashboard__leaderboard__pagination__btn-prev"
        disabled={currentPage === 1 || currentPage === 0}
        onClick={handlePrevPage}
      >
        {currentPage === 1 || currentPage === 0 ? (
          <ChevronLeftIcon />
        ) : (
          <ChevronLeftActiveIcon />
        )}
      </Button>
      <Label type="primary" size="small">
        Page {currentPage} of {totalPage}
      </Label>
      <Button
        styles="dashboard__leaderboard__pagination__btn-next"
        disabled={currentPage === totalPage}
        onClick={handleNextPage}
      >
        {currentPage === totalPage ? (
          <ChevronRightIcon />
        ) : (
          <ChevronRightActiveIcon />
        )}
      </Button>
    </div>
  );

  useEffect(() => {
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSortFilterOpen, isTimeFilterOpen]);

  return (
    <div
      className="dashboard__leaderboard__btrst"
      data-aos="fade-up"
      data-aos-delay="1"
    >
      <div
        className={`dashboard__leaderboard__btrst__main ${`dashboard__leaderboard__btrst__main__${
          showIntro ? "hide" : "show"
        }`}`}
      >
        <div className="dashboard__leaderboard__btrst__header">
          <div className="dashboard__leaderboard__btrst__header__title-and-cta">
            <Title
              styles="dashboard__leaderboard__btrst__header__title"
              type="primary"
              size="small"
              font="medium"
            >
              {totalNumberOfReferrers} referrers
            </Title>
            <Button
              styles="dashboard__leaderboard__btrst__header__join-button"
              border="round"
              type="primary"
              size="small"
              onClick={() => {
                return (window.location.href = `${process.env.REACT_APP_BRAINTRUST_URL}refer-clients`);
              }}
            >
              Join as connector
            </Button>
          </div>
          <Button onClick={onToggle}>
            <BlackDetailIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__leaderboard__btrst__subheader">
          <TabCarousel
            list={BTRST_EARNERS_FILTERS}
            selected={
              filters?.order.charAt(0) === "-"
                ? filters?.order.slice(1)
                : filters?.order
            }
            property="order"
            onTabItemClick={updateFilter}
          />
          <div className="dashboard__leaderboard__btrst__subheader__filter">
            <div className="dashboard__leaderboard__btrst__subheader__filter__left">
              <Dropdown
                open={isTimeFilterOpen}
                selected={
                  !filters?.year
                    ? DEFAULTS_EARNERS_FILTERS_VALUE.year
                    : filters.year
                }
                menu={tokenYearsOption}
                wrapperRef={timeFilterRef}
                onToggle={() => setTimeFilterOpen(!isTimeFilterOpen)}
                property="year"
                onItemClick={updateFilter}
                iconColor="red"
              />
            </div>
            <div className="dashboard__leaderboard__btrst__subheader__filter__right">
              <Dropdown
                open={isSortFilterOpen}
                prefix="Sort"
                position="right"
                selected={filters?.sort}
                menu={sorts.slice(-2)}
                wrapperRef={sortRef}
                onToggle={() => setSortFilterOpen(!isSortFilterOpen)}
                property="sort"
                onItemClick={updateFilter}
                iconColor="red"
              />
            </div>
          </div>
        </div>
        <div className="dashboard__leaderboard__btrst__list">
          {earnerList.map((item) => (
            <div className="dashboard__leaderboard__btrst__item" key={item.id}>
              <div className="dashboard__leaderboard__btrst__item__left">
                <Avatar
                  avatarUrl={item.avatar}
                  firstName={item.first_name}
                  lastInitial={item.last_initial}
                />
                <Label
                  className="text-ellipsis"
                  type="primary"
                  size="large"
                  font="medium"
                  title={item.label}
                >
                  {item.label}
                </Label>
              </div>
              <div className="dashboard__leaderboard__btrst__item__right">
                {filters.order.includes("tokens") && (
                  <LogoIcon width={16.79} height={16.15} />
                )}
                <Label font="medium" type="primary" size="large">
                  {item.value}
                </Label>
              </div>
            </div>
          ))}
        </div>
        <Pagination />
      </div>
      <div
        className={`dashboard__leaderboard__btrst__intro ${`dashboard__leaderboard__btrst__intro__${
          showIntro ? "show" : "hide"
        }`}`}
      >
        <div className="dashboard__leaderboard__btrst__header">
          <Title type="primary" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseBlackIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__leaderboard__btrst__intro__content">
          <div className="dashboard__leaderboard__btrst__intro__item">
            <Label type="primary" size="large" font="medium">
              Referrers
            </Label>
            <br />
            <Label type="primary" size="medium">
              The leaderboard recognizes Braintrust users that have earned BTRST
              by referring clients and talents to the Braintrust network. Users
              that refer clients receive 2% of their total billings in BTRST -
              up to 10k USD. Users that refer talent receive 1% of their total
              earnings in BTRST - up to 10k USD.
            </Label>
          </div>
          <div className="dashboard__leaderboard__btrst__intro__item">
            <Label type="primary" size="large" font="medium">
              How do I start referring clients and talents to Braintrust?
            </Label>
            <br />
            <Label type="primary" size="medium">
              Create a Braintrust account and share your unique referral code
              (available after sign-up) with clients and talent that are a great
              fit for Braintrust. You can find more information on the EARN tab
              in the Braintrust platform.
            </Label>
          </div>
        </div>
      </div>
    </div>
  );
}

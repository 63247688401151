import React, { useState } from "react";

import Button from "../Button";
import Label from "../Label";
import Title from "../Title";

import { numberWithCommas } from "../../util";
import { CloseWhiteIcon, DetailIcon } from "../../assets/svgs";

export default function ProjectMetrics({
  avg_project_size,
  avg_project_duration,
}) {
  const [showIntro, setShowIntro] = useState(false);

  const onToggle = () => {
    setShowIntro(!showIntro);
  };

  return (
    <div className="dashboard__project-metrics" data-aos="fade-up" data-aos-delay="50">
      <div className="dashboard__project-metrics__overlay" />
      <div
        className={`dashboard__project-metrics__main ${`dashboard__project-metrics__main__${
          showIntro ? 'hide' : 'show'
        }`}`}
      >
        <div className="dashboard__project-metrics__header">
          <Title type="white" size="small" font="medium">
            Braintrust project metrics
          </Title>
          <Button onClick={onToggle}>
            <DetailIcon className="icon__detail" />
          </Button>
        </div>
        <div className="project-metrics-container dashboard__project-metrics__content">
          <div>
            <Label type="white" size="large">
              Average Project Size
            </Label>
            <p className="project-metrics-container__dollar-amount">
              ${numberWithCommas(Math.floor(avg_project_size))}
            </p>
          </div>
          <div className="dashboard__project-metrics__content__apd">
            <Label type="white" size="large">
              Average Project Duration
            </Label>
            <p className="project-metrics-container__dollar-amount">{avg_project_duration ?? 0} days</p>
          </div>
        </div>
      </div>
      <div
        className={`dashboard__project-metrics__intro ${`dashboard__project-metrics__intro__${
          showIntro ? 'show' : 'hide'
        }`}`}
      >
        <div className="dashboard__project-metrics__header">
          <Title type="white" size="medium" font="medium">
            Definitions
          </Title>
          <Button onClick={onToggle}>
            <CloseWhiteIcon className="icon__detail" />
          </Button>
        </div>
        <div className="dashboard__project-metrics__intro__content">
          <div className="dashboard__project-metrics__intro__content__aps">
            <Label size="large" type="white" font="medium">
              Average Project Size
            </Label>
            <br />
            <Label size="medium" type="white">
              This metric uses previously submitted invoices. Average Project size is calculated by the number of weeks
              of a project x hrs/week x hourly rate.
            </Label>
          </div>
          <div className="dashboard__project-metrics__intro__content__apd">
            <Label size="large" type="white" font="medium">
              Average Project Duration
            </Label>
            <br />
            <Label size="medium" type="white">
              This metric is pulled based on the number of active and completed projects. The start date is set when an
              offer is made to talent, and the end date is calculated based on when the project is marked as complete,
              or the anticipated end date. We average the total project duration, and round to the nearest half month.
            </Label>
          </div>
        </div>
        <div />
      </div>
    </div>
  );
}
